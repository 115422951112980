<!--
 * @Author: Neko
 * @Date: 2021-01-07 15:44:47
 * @LastEditTime: 2021-08-06 17:14:46
 * @LastEditors: Neko
-->
<template>
  <div class="login">
    <div class="login__header">
      <ul class="tab">
        <li
          class="item"
          :class="{ active: active === 0 }"
          @click="onClickTab(0)"
        >
          <a
            href="javascript:void(0)"
            class="link"
          >{{ $t('login.member') }}&nbsp;<span style="color: #0049ac; font-weight: 600;">{{ $t('login.sign_in') }}</span></a>
        </li>
        <li
          class="item"
          :class="{ active: active === 1 }"
          @click="onClickTab(1)"
        >
          <a
            href="javascript:void(0)"
            class="link"
          >{{ $t('login.not_member') }}&nbsp;<span style="color: #0049ac; font-weight: 600;">{{ $t('login.sign_up') }}</span></a>
        </li>
      </ul>
    </div>
    <div class="login__title">
      {{ shopData.storeName }}&nbsp;{{ active === 0 ? $t('login.sign_in') : $t('login.register') }}
    </div>
    <div class="login__body">
      <el-form
        v-show="active === 0"
        ref="loginForm"
        class="form"
        :model="formData"
        @submit.native.stop="onLogin"
      >
        <el-form-item class="form-item">
          <el-input
            v-model="formData.email"
            clearable
            class="input"
            :placeholder="$t('login.email')"
          >
            <span slot="prepend" class="label">{{ $t('login.email') }}</span>
          </el-input>
        </el-form-item>

        <el-form-item class="form-item small">
          <el-input
            v-model="formData.password"
            clearable
            type="password"
            show-password
            class="input"
            :placeholder="$t('login.password')"
          >
            <span slot="prepend" class="label">{{ $t('login.password') }}</span>
          </el-input>
        </el-form-item>

        <button class="none" type="submit" />
      </el-form>

      <el-form
        v-show="active === 1"
        ref="formRef"
        class="form"
        :model="formData"
        :rules="ruler"
        @submit.native.stop="onLogin"
      >
        <el-form-item class="form-item" prop="email" :show-message="false">
          <el-input
            v-model="formData.email"
            clearable
            class="input"
            :placeholder="$t('login.email')"
          >
            <span slot="prepend" class="label">{{ $t('login.email') }}</span>
          </el-input>
        </el-form-item>

        <el-form-item class="form-item small" prop="password" :show-message="false">
          <el-input
            v-model="formData.password"
            clearable
            type="password"
            show-password
            class="input"
            :placeholder="$t('login.password')"
          >
            <span slot="prepend" class="label">{{ $t('login.password') }}</span>
          </el-input>

          <!-- <SendCode
            class="send-code"
            :before-start="onBeforeStart"
            @start="onSendCode"
          /> -->
        </el-form-item>

        <button class="none" type="submit" />
      </el-form>
    </div>
    <div class="login__operations">
      <div class="buttons no-margin">
        <el-button class="button" @click="onLogin">{{ active === 0 ? $t('login.sign_in') : $t('login.sign_up') }}</el-button>
      </div>

      <div class="buttons" style="margin-top: 50px">
        <router-link
          v-if="active === 0"
          class="link"
          :to="{ name: 'UserForgotPassword' }"
        >{{ $t('login.forget_password') }}</router-link>
        <div
          v-else
          class="regulations"
        >
          {{ $t('login.agreement') }}
          <router-link
            style="color: #0049AC"
            :to="{
              name: 'ArticlePage',
              query: { name: 'PRIVACY_POLICY' }
            }"
            target="_blank"
          >
            《{{ $t('login.private') }}》
          </router-link>{{ $t('login.and') }}
          <router-link
            style="color: #0049AC"
            target="_blank"
            :to="{
              name: 'ArticlePage',
              query: { name: 'TERMS_OF_CONDITIONS' }
            }"
          >
            {{ $t('order.termsOfUse') }}
          </router-link>
        </div>
        <!-- <router-link
          class="link"
          :to="{ name: 'UserRegister' }"
        >立即注册</router-link> -->

      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, ref } from '@vue/composition-api'
// import SendCode from '@/components/Common/SendCode'
import { Base64 as _Base64 } from 'js-base64'
import { Message } from 'element-ui'
import { getDomainPrefix, validateMobile } from '@/utils'
import { useRequest } from '@/utils/request.js'
import { Base64 } from '@/utils/base64'

export default {
  name: 'Login',

  components: {
    // SendCode
  },

  setup(_, { root }) {
    const { $store, $router, $route, $jst } = root

    const shopData = computed(() => $store.state.app.shopData)

    const formRef = ref(null)
    const loginForm = ref(null)

    const blur = { required: true, message: $jst('common.blur_info'), trigger: 'blur' }

    const data = reactive({
      active: 0,
      formData: {
        email: '',
        password: ''
      },

      ruler: {
        mobile: [blur, { validator: validateMobile, trigger: 'blur' }],
        validateCode: blur
      },

      hostUrl: window.location.protocol + '//' + process.env.VUE_APP_HOST_URL
    })

    const resetForm = _ => {
      loginForm.value && loginForm.value.resetFields()
      formRef.value && formRef.value.resetFields()
    }

    const { fetch: login } = useRequest('user/login', {
      data: computed(() => ({
        email: data.formData.email,
        password: Base64.encode(data.formData.password),
        domainPrefix: getDomainPrefix
      })),
      immediate: false,
      onSuccess: (res) => {
        const { data } = res
        $store.dispatch('user/Login', data)
        resetForm()
        redirect($router)
      }
    })

    const { fetch: register } = useRequest('user/register', {
      data: computed(() => ({
        email: data.formData.email,
        password: Base64.encode(data.formData.password),
        domainPrefix: getDomainPrefix
      })),
      immediate: false,
      onSuccess: (res) => {
        const { code } = res
        if (code === 200) {
          Message.success($jst('login.register_successful'))
          resetForm()
          // $router.replace({ path: '/user/login' })
          data.active = 0
        }
      }
    })

    const { fetch: sendCode } = useRequest('user/sendSmsCode', {
      data: computed(() => ({
        mobile: data.formData.mobile
      })),
      immediate: false,
      onSuccess: (res) => {}
    })

    const onClickTab = (index) => {
      data.active = index
      data.formData = {
        email: '',
        password: ''
      }

      resetForm()
    }

    const onLogin = (e) => {
      const { email, password } = data.formData

      if (email.length <= 0) {
        Message.error($jst('login.email_required'))
        return
      }

      if (password.length <= 0) {
        Message.error($jst('login.password_required'))
        return
      }

      if (data.active === 0) {
        login()
      } else {
        register()
      }

      e.preventDefault()
    }

    const onSendCode = () => {
      sendCode()
    }

    const redirect = (router) => {
      if ($route.query.redirect) {
        const query = JSON.parse(_Base64.decode($route.query.redirect || ''))

        if (sessionStorage.redirect) {
          const redirectUrl = sessionStorage.redirect

          $store.commit('app/SET_REDIRECT_QUERY', query)

          router.replace({ path: redirectUrl })
          sessionStorage.redirect = ''
        } else {
          router.replace({ path: '/profile' })
        }
      } else {
        router.replace({ path: '/profile' })
      }
    }

    const onBeforeStart = () => {
      let result = false
      formRef.value.validateField('mobile', (res) => {
        if (res) {
          Message.error(res)
          result = false
        } else {
          result = true
        }
      })

      return result
    }

    return {
      ...toRefs(data),
      formRef,
      shopData,
      onClickTab,
      onLogin,
      onSendCode,
      onBeforeStart
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style/common.scss';

.login {
  padding-bottom: 53px;
  background: #FFFFFF;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
}

.tab {
  display: flex;
  justify-content: space-between;

  .item {
    width: 50%;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: #e8e8ea;
    .link {
      position: relative;
      // display: block;
      padding-bottom: 2px;
      font-size: 16px;
      word-spacing: 1px;
      color: #444;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: transparent;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        transition: .2s all;
      }
    }

    &.active {
      // font-weight: bold;
      background-color: #fff;

      .link {
        // position: relative;
        font-size: 18px;
        // color: #0049AC;
        // border-bottom: 2px solid #0049ac;

        &::after {
          content: '';
          width: 100%;
          background: #0049ac;
        }

        // &::after {
        //   content: '';
        //   position: absolute;
        //   width: 21px;
        //   height: 4px;
        //   bottom: 0;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   border-radius: 2px;
        //   background: #4981E8;
        // }
      }
    }
  }
}

.login__title {
  color: #0049ac;
  font-size: 22px;
  margin: 93px 0 45px 0;
  text-align: center;
}

.login__body {
  width: 360px;
  box-shadow: 0px 9px 22px 2px rgba(232, 232, 234, 0.95);
  border-radius: 8px;
  margin: auto;

  .form {
    @include form;
  }

  .none {
    display: none;
  }
}

.login__operations {
  width: 360px;
  margin: auto;
  .buttons {
    // display: flex;
    // justify-content: space-between;

    &.no-margin {
      margin-bottom: 0;
    }

    .link {
      display: inline-block;
      border-bottom: 1px solid #CAD0E8;
      font-size: 14px;
      line-height: 20px;
      color: #4981e8;

      &.grey {
        color: #999;
      }
    }

    .button {
      @include button;
    }

    .regulations {
      text-align: center;
      color: #666666;
      font-size: 14px;
    }
  }
}

.form-item {
  ::v-deep .el-input-group__prepend {
    padding: 0;
    border: none;
    border-bottom: 1px solid #eee;
    // border-right: 1px solid #eee;
    background: #fff;
    color: #AAAAAA;

    .label {
      display: inline-block;
      padding: 0 33px;
      border-right: 1px solid #eee;
    }
  }
}
</style>
